const COMPONENT_NAMES = {
    // Ideally these go in the general order that the steps might appear
    POSTAL_CODE: 'PostalCode',
    SHOPPING_FOR: 'ShoppingFor',
    PRODUCT_INTEREST: 'ProductInterest',
    EV_CHARGER: 'EvCharger',
    PROPERTY_TYPE: 'PropertyType',
    BUSINESS_CONTACT: 'BusinessContact',
    OWNS_PROPERTY: 'OwnsProperty',
    OWNS_PROPERTY_COMMERCIAL: 'OwnsPropertyCommercial',
    TIME_TO_PURCHASE: 'TimeToPurchase',
    RENT_CRITERIA: 'RentCriteria',
    CSM_HANDOFF: 'CsmHandoff',
    SUSTAINABILITY: 'Sustainability',
    ELECTRICITY_BILL: 'ElectricityBill',
    EV_DETAILS: 'EvDetails',
    SERVICE_ERROR: 'ServiceError',
    STORAGE_INTEREST: 'StorageInterest',
    STORAGE_REASON: 'StorageReason',
    ADDRESS: 'Address',
    ROOF_PIN: 'RoofPin',
    CONFIRM_ADDRESS: 'ConfirmAddress',
    ROOF_PIN_HELP: 'RoofPinHelp',
    ROOF_AGE: 'RoofAge',
    REMOVE_TREES: 'RemoveTrees',
    ORGANIZATION_NAME: 'OrganizationName',
    NAME: 'Name',
    EMAIL: 'Email',
    PHONE: 'Phone',
    VERIFY_COMPLETE: 'VerifyComplete',
    SUMMARY: 'Summary',
    PANEL_DISTANCE: 'PanelDistance',
    GREAT_CHOICE: 'GreatChoice',
    UTILITY_PROVIDER: 'UtilityProvider',
    OWNED_APPLIANCES: 'OwnedAppliances',
    CONTACT_INFO: 'ContactInfo',
    PANEL_LOCATION: 'PanelLocation',
    ELECTRICAL_PANEL: 'ElectricalPanel',
    EV_THANKS: 'EvThanks',
    SQUARE_FOOTAGE: 'SquareFootage',
    YEAR_BUILT: 'YearBuilt',
    HOME_TYPE: 'HomeType',
    CEILING_HEIGHT: 'CeilingHeight',
    WHAT_TO_POWER: 'WhatToPower',
    INSTALLATION_TIMELINE: 'InstallationTimeline',
    CURRENT_ENERGY_STORAGE: 'CurrentEnergyStorage',
    BATTERY_DISTANCE: 'BatteryDistance',
    CURRENT_SOLAR_INVERTER: 'CurrentSolarInverter',
    CURRENT_SOLAR: 'CurrentSolar',
    NUMBER_OF_ROOMS: 'NumberOfRooms',
    FLOORS: 'Floors',
    HOME_COVERAGE: 'HomeCoverage',
    DUCTWORK: 'Ductwork',
    CONFIRM_INFORMATION: 'ConfirmInformation',
    BILL_UPLOAD: 'BillUpload',
};

const CHOICE_VALUES = {
    HEATPUMP: 'heatpump',
    SOLAR_PV: 'solar-pv',
    EV_CHARGER: 'ev-charger',
    BATTERY_STORAGE: 'BatteryStorage',
    PROJECT_UNITY: 'project_unity',
    EV_CHARGER_PROJECT_UNITY: 'ev-charger-project-unity',
    SOLAR_PV_EV_CHARGER_PROJECT_UNITY: 'solar-pv-ev-charger-project_unity',
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial',
    NONPROFIT: 'nonprofit',
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not_interested',
    BLANK: 'blank',
    BACKUP: 'EBP',
    SAVINGS: 'UR',
    SELF_SUPPLY: 'SS',
    OLD_ROOF: 'more_than_20',
    NEW_ROOF: 'less_than_20',
    OLD_ROOF_REPLACE: 'more_than_20_replacing',
    YES_REMOVE: 'yes',
    NO_REMOVE: 'no',
    NOT_SURE_TREE: 'not_sure',
    OWNER: 'owner',
    NON_OWNER: '',
    RENT_OTHER: 'shopping_someone_else',
    RENT_HOA: 'shopping_hoa_or_multicondo',
    RENT_SELF: 'rent_for_self',
    REPRESENT_OWNER: 'represent_owner',
    COMMERCIAL_OWNER: 'owner',
    COMMERCIAL_LEASE: 'commercial_lessee',
    WITHIN_MONTHS: 'next_few_months',
    WITHIN_YEAR: 'next_year',
    NOT_SURE: 'unsure',
    NEXT_TWO_MONTHS: 'next_two_months',
    NEXT_FOUR_MONTHS: 'next_three_to_four_months',
    OVER_FOUR_MONTHS: 'over_four_months',
    TRUE: 'true',
    FALSE: 'false',
    SCHEDULE_CALL: 'schedule_call',
    REQUEST_EMAIL: 'request_email',
    PANEL_DISTANCE_10: '0-10 ft',
    PANEL_DISTANCE_10_20: '10-20 ft',
    PANEL_DISTANCE_20_30: '20-30 ft',
    PANEL_DISTANCE_30: '30+ ft',
    HP_MONOLITH: 'hp_monolith',
    SERVICE_ERROR: 'service_error',
    CENTRAL_AC: 'CentralAc',
    ELECTRIC_OVEN: 'ElectricOven',
    ELECTRIC_DRYER: 'ElectricDryer',
    ELECTRIC_WATER_HEATER: 'ElectricWaterHeater',
    ELECTRIC_INDUCTION_COOKTOP: 'ElectricOrInductionCooktop',
    HEAT_SOURCE_GAS: 'Gas',
    HEAT_SOURCE_ELECTRICITY: 'Electricity',
    HEAT_SOURCE_OIL: 'Oil',
    HEAT_SOURCE_WOOD: 'Wood',
    HEAT_SOURCE_OTHER: 'Other',
    NONE: 'none',
    PANEL_LOCATION_GARAGE: 'garage',
    PANEL_LOCATION_BASEMENT: 'basement',
    PANEL_LOCATION_EXTERIOR: 'exterior_wall',
    PANEL_LOCATION_OTHER: 'other',
    CEILING_8FT_OR_LESS: 'ceiling_8ft_or_less',
    CEILING_9FT: 'ceiling_9ft',
    CEILING_10FT_OR_MORE: 'ceiling_10ft_or_more',
    EV_CHARGER_ADAPTER: 'EVChargerAdapter',
    INVERTER: 'Inverter',
    SMART_PANEL: 'SmartPanel',
    ESSENTIALS: 'essentials',
    ESSENTIALS_PLUS: 'essentials_plus',
    EVERYTHING: 'everything',
    THERMOSTAT: 'Thermostat',

    OTHER_UTILITY: 'other_utility',
    SINGLE_FAMILY_HOUSE: 'single_family_house',
    CONDO: 'condo',
    TOWNHOUSE: 'townhouse',
    OTHER: 'other',
    EMERGENCY: 'emergency',
    NON_EMERGENCY: 'non_emergency',
    INSTALLATION_ONE_TO_TWO_MONTHS: 'installation_one_to_two_months',
    INSTALLATION_TWO_TO_THREE_MONTHS: 'installation_two_to_three_months',
    INSTALLATION_FOUR_OR_MORE_MONTHS: 'installation_four_or_more_months',
    INSTALLATION_UNSURE: 'installation_unsure',
    OTHER_ENERGY_STORAGE: 'other_energy_storage',
    NONE_ENERGY_STORAGE: 'none_energy_storage',
    TESLA_ENERGY_STORAGE: 'tesla_energy_storage',
    SOLAREDGE_ENERGY_STORAGE: 'solaredge_energy_storage',
    ENPHASE_ENERGY_STORAGE: 'enphase_energy_storage',
    BATTERY_DISTANCE_LESS_THAN_10: 'battery_less_than_10_feet',
    BATTERY_DISTANCE_10_20: 'battery_between_10_20_feet',
    BATTERY_DISTANCE_MORE_THAN_20: 'battery_more_than_20_feet',
    OTHER_SOLAR_INVERTER: 'other_solar_inverter',
    NONE_SOLAR_INVERTER: 'none_solar_inverter',
    TESLA_SOLAR_INVERTER: 'tesla_solar_inverter',
    SOLAREDGE_SOLAR_INVERTER: 'solaredge_solar_inverter',
    ENPHASE_SOLAR_INVERTER: 'enphase_solar_inverter',
    YES_SOLAR_OWN: 'yes_solar_own',
    YES_SOLAR_RENT: 'yes_solar_rent',
    NO_SOLAR: 'no_solar',
    FLOORS_ONE: 'floors_one',
    FLOORS_TWO: 'floors_two',
    FLOORS_THREE: 'floors_three',
    FLOORS_FOUR_OR_MORE: 'floors_four_or_more',
    HOME_COVERGE_WHOLE_HOME: 'home_coverage_whole_home',
    HOME_COVERGE_PARTIAL_HOME: 'home_coverage_partial_home',
    HOME_COVERAGE_UNSURE: 'home_coverage_unsure',
    DUCTWORK_YES: 'ductwork_yes',
    DUCTWORK_NO: 'ductwork_no',
    EA_AND_INSTALLER: 'phone_es_and_installers',
    EA_ONLY: 'phone_es_only',
    SKIP_FOR_NOW: 'skip_for_now',
};

const NUMERIC_CONSTANT_VALUES = {
    CO2_TONS_PER_KWH: 0.0007601,
    CO2_TREES_PER_KWH: 0.0176808,
    AVG_NATIONAL_ELECTRICITY_BILL: 23, // 23 cents/kwh
};

const SERVICE_LEVEL_VALUES = {
    FULL: 'full',
    LIMITED: 'limited',
    NONE: 'none',
};

const handler = {
    get(target, name) {
        if (name in target) {
            return target[name];
        }
        throw new Error(`invalid key: ${name}`);
    },
};

export const COMPONENTS = new Proxy(COMPONENT_NAMES, handler);
export const COMPONENT_COUNT = Object.keys(COMPONENT_NAMES).length;
export const CHOICES = new Proxy(CHOICE_VALUES, handler);
export const NUMERIC_CONSTANT = new Proxy(NUMERIC_CONSTANT_VALUES, handler);
export const SERVICE_LEVEL = new Proxy(SERVICE_LEVEL_VALUES, handler);
export const COMMERCIAL_PROPERTY_TYPES = [CHOICES.COMMERCIAL, CHOICES.NONPROFIT];

export const LOGGED_IN_STEPS = [
    COMPONENTS.VERIFY_COMPLETE,
    COMPONENTS.EMAIL,
    COMPONENTS.SERVICE_ERROR,
    COMPONENTS.GREAT_CHOICE,
    COMPONENTS.PANEL_DISTANCE,
    COMPONENTS.PANEL_LOCATION,
    COMPONENTS.ELECTRICAL_PANEL,
    COMPONENTS.EV_THANKS,
];
export const PROPERTY_OVERRIDE_COOKIE_NAME = 'mfdp_mvp_property_type_override';
const COOKIE_PATH = '/';
const MAX_AGE_IN_SECONDS = 15;
const SAME_SITE_RULE = 'lax';
export const PROPERTY_OVERRIDE_COOKIE_PARAMS = {
    path: COOKIE_PATH,
    maxAge: MAX_AGE_IN_SECONDS,
    sameSite: SAME_SITE_RULE,
};
export const ERROR_REASON = {
    EMAIL_EXISTS: 'EMAIL_EXISTS',
    SUBMISSION: 'SUBMISSION',
    COOKIE_AUTHORIZATION: 'COOKIE_AUTHORIZATION',
    NO_SERVICE: 'NO_SERVICE',
    INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
    FILE_TOO_LARGE: 'FILE_TOO_LARGE',
    UNKNOWN: 'UNKNOWN',
};

export const GOOGLE_MAPS_API_MAX_LOAD_TIME = 1500;

export const GOOGLE_MAPS_API_LIBRARIES = {
    CORE: 'core',
    MAPS: 'maps',
    MARKER: 'marker',
    PLACES: 'places',
};

export const ELECTRICITY_BILL_FILE_TYPES = ['image/jpeg', 'image/png', 'image/tiff', 'application/pdf'];

export const HP_EA_PHONE_NUMBER = '(617) 284-4960';
