import { defineStore } from 'pinia';
import { useContext } from '@nuxtjs/composition-api';
import * as Sentry from '@sentry/browser';
import { POSTAL_CODE_UTILITIES_ENDPOINT, POSTAL_CODE_UTILITIES_SUCCESS, MultiPropertyStatus } from './constants';
import { getMaxStorageSavings } from './utils';
import { CHOICES, COMMERCIAL_PROPERTY_TYPES } from '@/constants';
import { jwtDecode } from 'jwt-decode';
import { Ref, ref } from 'vue';
import { usePropertiesTimeToQuoteStore } from './properties';
import { isMobilePhone, isEmail } from 'validator';

interface EnergyInformationAdministrationIDNameMapping {
    eiaid: number;
    name: string;
}

interface EnergyInformationAdministrationIDNameMappingResponse {
    utilities: EnergyInformationAdministrationIDNameMapping[];
}
type evDetail = {
    id: string;
    year: number;
    connectorType: string;
    isDER: boolean;
    make: string;
    model: string;
    fuelCode: string;
    batterySize: number;
    maxAmps: number;
    onBoardCharger: number;
};

type Rebate = {
    minimumRebate: number;
    maximumRebate: number;
};

type Address = {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    county: string;
    latitude: number;
    longitude: number;
    fullAddress: string;
    shopperServiceSiteId: string;
    marketPropertyID: number;
    hasMultiPropEnabled: boolean;
    eaCalendarLink: string;
};

type ExistingProductsRecord = {
    products?: string[];
    address?: Address;
};

type ExistingProductsApiResponse = {
    records: ExistingProductsRecord[];
};

// eslint-disable-next-line import/prefer-default-export
export const useRegistrationStore = defineStore('registration', () => {
    const { $cookies, $axios, route, $config } = useContext();
    const solarCalculator = ref(false);
    const propertyType = ref(null);
    const ownsProperty: Ref<string | null> = ref(null);
    const commercialOwnershipType: Ref<string | null> = ref(null);
    const monthlyElectricityBill = ref(200);
    const storageInterest = ref('');
    const storageReason = ref('');
    const streetAddress = ref('');
    const postalCode = ref('');
    const postalCodeUnprocessed = ref(false);
    const postalCodeChoice = ref('');
    const latitude = ref(0);
    const longitude = ref(0);
    const city = ref('');
    const county = ref('');
    const state = ref('');
    const fullAddress = ref('');
    const ownedAppliances: Ref<string[]> = ref([]);
    const panelLocation: Ref<string | null> = ref(null);
    // productInterest is an array representing the products the user has shown interest in during the current onboarding session.
    const productInterest: Ref<string[]> = ref([]);
    // productInterestScreenChoice indicates the flow the user chooses after selecting their product interests. See flows.json for more information.
    const productInterestScreenChoice = ref('');
    const installationTimeline = ref('');
    const yearBuilt = ref('');
    const utilityName = ref('');
    const projectUnityTestCookieExists = ref<boolean>(false);
    const solarEnergyStorage = ref('');
    const batteryDistance = ref('');
    const solarInverterType = ref('');
    const currentSolar = ref('');
    const numberOfRooms = ref(1);
    const floors = ref(null);
    const homeCoverage = ref('');
    const ductwork = ref('');

    const roofPinConfirmed = ref(false);
    const roofAge = ref('');
    const canRemoveTrees = ref(null);
    const companyName = ref(null);
    const firstName: Ref<string | null> = ref(null);
    const lastName: Ref<string | null> = ref(null);
    const email: Ref<string | null> = ref(null);
    const phone: Ref<string | null> = ref(null);
    const referralCode: Ref<string | null> = ref(null);
    const isCsmZip = ref(null);
    const progressPercentage = ref(0);
    const accountCreated = ref(false);
    const storageSavingsDifference: Ref<number | null> = ref(null);
    const personalization = ref(false);
    const sustainability = ref(false);
    const rooftopSolarEligibilityStatus: Ref<string | null> = ref(null);
    const rentCriteria: Ref<string | null> = ref(null);
    const organizationName: Ref<string | null> = ref(null);
    const timeToPurchase: Ref<string | null> = ref(null);
    const shoppingFor: Ref<string | null> = ref(null);
    const inEvRefinement = ref(false);
    const inPartnerHandoff = ref(false);
    const partnerCode: Ref<string | null> = ref(null);
    const partnerHandoffJwt: Ref<string | null> = ref(null);
    const evVersion: Ref<evDetail | null> = ref(null);
    const rebateValues = ref<Record<string, Rebate> | null>(null);
    const availableUtilities: Ref<string[]> = ref([]);
    const partnerHandoffData: Ref<any> = ref({});
    // existingProductInterestsForUser represents the products the user has shopped for during previous onboarding sessions.
    const existingProductInterestsForUser: Ref<string[] | null> = ref(null);
    const existingAddresses: Ref<Address[]> = ref([]);
    // An array maintaining a list of products that people are shopping for through 1QPS.
    const desiredProducts: Ref<string[]> = ref([]);
    // IDR Test
    const csmHandoffFlag = ref(false);
    const shoppingForFlag = ref(false);
    const evCharger: Ref<string | null> = ref(null);
    const panelImage: Ref<File | null> = ref(null);
    const squareFootage: Ref<number | null> = ref(null);
    const panelToChargerDistance: Ref<string | null> = ref(null);
    const ceilingHeight = ref('');
    const hasPhoneNumber = ref(false);
    const electricityBillFile: Ref<File | null> = ref(null);
    const skipConfirmExistingAddress = ref(true);
    const propertiesTimeToQuoteStore = usePropertiesTimeToQuoteStore();
    const onboardingInitialized = ref(false); // TODO: IDR-1033 Create a durable approach to es-onboarding initialization and rendering order
    const disableEmail = ref(false);
    const multiPropStatus: Ref<MultiPropertyStatus> = ref(MultiPropertyStatus.NOT_DETERMINED);
    // Set to true after a call to BFF endpoint /existing-products.
    const calledExistingProductInterestsEndpoint = ref(false);

    async function fetchAverageElectricityBillForZip() {
        // We check if we are in the development environment (localhost) and return prematurely
        // to avoid a call to es-site
        // TODO: IDR-475 - move this to Axios Interceptor.
        if (process.env.NODE_ENV === 'development') {
            return;
        }
        try {
            const response = await $axios.get('/local-data/avg-electricity-bill/', {
                params: {
                    postal_code: postalCode.value, // should not be from state
                },
            });
            if (response.data?.bill) {
                const roundedAverageBill = Math.round(response.data.bill / 10) * 10;
                // @ts-expect-error dynamicRoutingMap does not handle types well
                this.monthlyElectricityBill = roundedAverageBill;
            }
        } catch (e) {}
    }
    async function fetchRebateValues() {
        const MAX_LOAD_TIME = 4000;
        if (!postalCode.value || !utilityName.value) {
            rebateValues.value = null;
            return;
        }
        try {
            const response = await $axios.get(`${$config.ONBOARDING_BFF_DOMAIN}/v1/product_rebates`, {
                params: {
                    zip_code: postalCode.value,
                    utility: utilityName.value,
                },
                timeout: MAX_LOAD_TIME,
            });
            if (response.status === 200) {
                rebateValues.value = response.data;
            }
        } catch (err) {
            if (err instanceof Error) {
                if (err.message.includes('timeout')) {
                    Sentry.withScope((scope) => {
                        scope.setTag('acceptable_max_load_time', `${MAX_LOAD_TIME} ms`);
                        Sentry.captureMessage('Product rebates took too long to load.', 'error');
                    });
                } else {
                    Sentry.captureMessage(`Product rebates endpoint failed with error: ${err}`, 'error');
                }
            }
        }
    }

    function setReferralCode(code: any): boolean {
        if (code && typeof code === 'string') {
            referralCode.value = code;
            return true;
        }
        return false;
    }

    function setFirstName(name: any): boolean {
        if (name && typeof name === 'string' && name.length > 1) {
            firstName.value = name;
            return true;
        }
        return false;
    }

    function setLastName(name: any): boolean {
        if (name && typeof name === 'string' && name.length > 1) {
            lastName.value = name;
            return true;
        }
        return false;
    }

    function setEmail(em: any): boolean {
        if (em && typeof em === 'string' && isEmail(em)) {
            email.value = em;
            return true;
        }
        return false;
    }

    function setPostalCode(code: any): boolean {
        if (code && typeof code === 'string' && /^\d{5}$/.test(code)) {
            postalCode.value = code;
            return true;
        }
        return false;
    }

    function setLatitude(lat: any): boolean {
        if (typeof lat === 'string' && Number(lat)) {
            latitude.value = Number(lat);
            return true;
        }
        if (typeof lat === 'number') {
            latitude.value = lat;
            return true;
        }
        return false;
    }

    function setLongitude(lon: any): boolean {
        if (typeof lon === 'string' && Number(lon)) {
            longitude.value = Number(lon);
            return true;
        }
        if (typeof lon === 'number') {
            longitude.value = lon;
            return true;
        }
        return false;
    }

    function setMonthlyElectricityBill(bill: any): boolean {
        if (typeof bill === 'number' && bill.toString().length <= 11) {
            monthlyElectricityBill.value = Math.round(bill / 10) * 10;
            return true;
        }
        if (typeof bill === 'string' && bill.length <= 11 && Number(bill)) {
            monthlyElectricityBill.value = Math.round(Number(bill) / 10) * 10;
            return true;
        }
        return false;
    }

    function setStreetAddress(address: any): boolean {
        if (address && typeof address === 'string') {
            streetAddress.value = address;
            return true;
        }
        return false;
    }

    function setCity(cityName: any): boolean {
        if (cityName && typeof cityName === 'string') {
            city.value = cityName;
            return true;
        }
        return false;
    }

    function setState(stateName: any): boolean {
        if (stateName && typeof stateName === 'string') {
            state.value = stateName;
            return true;
        }
        return false;
    }

    function setFullAddress(address: any): boolean {
        if (address && typeof address === 'string') {
            fullAddress.value = address;
            return true;
        }
        return false;
    }

    function setPhoneNumber(phoneNumber: any): boolean {
        if (typeof phoneNumber === 'string' && isMobilePhone(phoneNumber, 'en-US')) {
            phone.value = phoneNumber;
            return true;
        }
        return false;
    }

    function mapPartnerHandoffDataToRegistrationFields(partnerHandoffData: any, partnerCode: string) {
        const responses = new Map<string, boolean>();
        responses.set('referralCode', setReferralCode(partnerHandoffData.prg));
        responses.set('firstName', setFirstName(partnerHandoffData.fn));
        responses.set('lastName', setLastName(partnerHandoffData.ln));
        responses.set('email', setEmail(partnerHandoffData.em));
        responses.set('postalCode', setPostalCode(partnerHandoffData.pc));
        responses.set('latitude', setLatitude(partnerHandoffData.lat));
        responses.set('longitude', setLongitude(partnerHandoffData.lon));
        responses.set('monthlyElectricityBill', setMonthlyElectricityBill(partnerHandoffData.maed));
        responses.set('streetAddress', setStreetAddress(partnerHandoffData.sa));
        responses.set('city', setCity(partnerHandoffData.ci));
        responses.set('state', setState(partnerHandoffData.st));
        responses.set('fullAddress', setFullAddress(partnerHandoffData.fa));
        responses.set('phoneNumber', setPhoneNumber(partnerHandoffData.ph));

        if (fullAddress.value) {
            skipConfirmExistingAddress.value = false;
        }
        // Check responses and log any missing or invalid fields
        const invalidFields: string[] = [];
        responses.forEach((item, field) => {
            if (!item) {
                invalidFields.push(field);
            }
        });
        if (invalidFields.length > 0) {
            Sentry.captureMessage(
                `Partner handoff data from partner with code ${partnerCode} is missing or invalid for fields: ${invalidFields.join(
                    ', ',
                )}`,
                'error',
            );
        }
    }

    async function decodeHandoffJwt() {
        if (!partnerCode.value || !partnerHandoffJwt.value) {
            return null;
        }
        try {
            const response = await $axios.get(
                `${$config.ONBOARDING_BFF_DOMAIN}/v1/partner-handoff/${partnerCode.value}/decode/${partnerHandoffJwt.value}`,
            );
            partnerHandoffData.value = response.data.handoffData;
            partnerHandoffData.value = response?.data?.handoffData;
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureMessage(`Partner handoff JWT decode failed with error: ${err}`, 'error');
            }
            return err;
        }
        if (partnerHandoffData.value != null) {
            mapPartnerHandoffDataToRegistrationFields(partnerHandoffData.value, partnerCode.value);
        }
        return partnerHandoffData.value;
    }

    async function fetchAvailableUtilities() {
        const MAX_LOAD_TIME = 10000;
        if (!postalCode.value) {
            const urlZipCode = route?.value?.query?.zip_code;
            if (urlZipCode) {
                postalCode.value = urlZipCode.toString();
            } else {
                return;
            }
        }
        try {
            const response = await $axios.get(`${$config.ONBOARDING_BFF_DOMAIN}/v1/utilities_by_zip`, {
                params: {
                    zip_code: postalCode.value,
                },
                timeout: MAX_LOAD_TIME,
            });
            if (response.data.length === 0) {
                Sentry.captureMessage(
                    `Utilities endpoint returned zero available utilities for the zip code: ${postalCode.value}`,
                    'warning',
                );
            }
            availableUtilities.value = response.data;
        } catch (err) {
            if (err instanceof Error) {
                if (err.message.includes('timeout')) {
                    Sentry.withScope((scope) => {
                        scope.setTag('acceptable_max_load_time', `${MAX_LOAD_TIME} ms`);
                        Sentry.captureMessage('Available utilities took too long to load.', 'error');
                    });
                } else {
                    Sentry.captureMessage(`Available utilities endpoint failed with error: ${err}`, 'error');
                }
            }
        }
    }

    function setStorageSavingsDifference(data: EnergyInformationAdministrationIDNameMappingResponse) {
        const energyInformationAdministrationIDs = data.utilities.map((utility) => utility.eiaid);
        storageSavingsDifference.value = getMaxStorageSavings(energyInformationAdministrationIDs);
    }

    async function fetchPostalCodeUtilities(postal_code: string) {
        const { status, data } = await $axios.get<EnergyInformationAdministrationIDNameMappingResponse>(
            POSTAL_CODE_UTILITIES_ENDPOINT,
            {
                params: {
                    postal_code,
                },
            },
        );
        if (status === POSTAL_CODE_UTILITIES_SUCCESS) {
            setStorageSavingsDifference(data);
        }
    }

    async function getPropData() {
        // We check if we are in the development environment (localhost) and return prematurely
        // to avoid a call to es-site
        // TODO: IDR-475 - move this to Axios Interceptor.
        if (process.env.NODE_ENV === 'development') {
            if (!postalCode.value) {
                setPostalCode(route?.value.query.zip_code?.toString());
            }
            return;
        }

        const csrfToken = $cookies?.get('csrftoken');
        // Return early to avoid a CORS error if the csrfToken is not set
        if (!csrfToken) {
            return;
        }
        const { data } = await $axios.get('/market/get-property-data');
        const prop_data = JSON.parse(data.property_data);
        if (!postalCode.value) {
            if (prop_data.postal_code) {
                setPostalCode(prop_data.postal_code);
            } else {
                setPostalCode(route?.value.query.zip_code?.toString());
            }
        }
        streetAddress.value = prop_data.address;
        latitude.value = prop_data.latitude;
        longitude.value = prop_data.longitude;
        city.value = prop_data.city;
        county.value = prop_data.county;
        state.value = prop_data.state;
        fullAddress.value = prop_data.successful_address;
        if (prop_data.monthly_electricity_cost) {
            monthlyElectricityBill.value = prop_data.monthly_electricity_cost;
        }
        propertyType.value = prop_data.property_type;
        roofPinConfirmed.value = prop_data.successful_address !== null;
    }

    async function fetchZipHasCsm(postal_code: any, csm_state: any) {
        // We check if we are in the development environment (localhost) and return prematurely
        // to avoid a call to es-site
        // TODO: IDR-475 - move this to Axios Interceptor.
        if (process.env.NODE_ENV === 'development') return {};

        const response = await $axios.get('/local-data/zip-in-csm/', {
            params: {
                postal_code,
                state: csm_state,
            },
        });
        // @ts-expect-error dynamicRoutingMap does not handle types well
        this.isCsmZip = response?.data?.zip_active_on_csm;

        return response;
    }
    async function fetchRooftopEligibility(postal_code: string) {
        const response = await $axios.get('/local-data/zip-rooftop-service/', {
            params: {
                postal_code,
            },
        });

        // @ts-expect-error no type for store definition
        this.rooftopSolarEligibilityStatus = response?.data?.zip_rooftop_service;
    }

    // Allows check for given prop if supplied, otherwise checks store value
    function isCommercial(newPropertyType?: string | null) {
        // Check for commercial and industrial property types
        return newPropertyType
            ? COMMERCIAL_PROPERTY_TYPES.includes(newPropertyType)
            : COMMERCIAL_PROPERTY_TYPES.includes(propertyType.value);
    }

    // Verify that we're using the new heat pump flow
    function inHeatPumpFlow() {
        return shoppingFor.value === CHOICES.HEATPUMP;
    }

    function inSolarPvFlow() {
        // @ts-expect-error no type for store definition
        return !this.inHeatPumpFlow();
    }

    function isProjectUnityEligible() {
        return projectUnityTestCookieExists.value;
    }

    function updateCommercialProductInterest() {
        const updatedProductInterest = [...productInterest.value];

        if (isCommercial()) {
            if (
                !updatedProductInterest.includes(CHOICES.SOLAR_PV) &&
                updatedProductInterest.includes(CHOICES.EV_CHARGER)
            ) {
                updatedProductInterest.push(CHOICES.SOLAR_PV);
            }
        }
        return updatedProductInterest;
    }

    async function uploadElectricityBillFile(pk: number) {
        const data = new FormData();
        const csrfToken = $cookies?.get('csrftoken');

        if (electricityBillFile.value != null) {
            data.append('eb_file', electricityBillFile.value, electricityBillFile.value.name);
        }
        try {
            await $axios.post(`/market/consumers/api/v1/properties/${pk}/electric-bill/`, data, {
                timeout: 8000,
                headers: { 'X-CSRFToken': csrfToken },
            });
        } catch (err) {
            Sentry.captureMessage(`Electricity bill upload failed with error: ${err}`, 'error');
        }
    }

    async function submitRegistration() {
        let isOwner = ownsProperty.value === 'true';
        if (
            ownsProperty.value === 'false' &&
            (rentCriteria.value === CHOICES.RENT_OTHER || rentCriteria.value === CHOICES.RENT_HOA)
        ) {
            isOwner = true;
            ownsProperty.value = CHOICES.TRUE;
        }
        const csrfToken = $cookies?.get('csrftoken');

        // The 'ownership_type' sent with registration is 'owner' if the person is a commercial owner or residential owner.
        let ownershipInfo: string | null = '';
        if (ownsProperty.value === CHOICES.TRUE) {
            ownershipInfo = 'owner';
        }

        // Renters representing HOAs or multi-unit condos have 'ownership_type' set to 'shopping_hoa_or_multicondo'
        if (rentCriteria.value === CHOICES.RENT_HOA) {
            ownershipInfo = rentCriteria.value;
        }

        // Renters shopping for others are treated the same as commercial users representing owners
        // where 'ownership_type' is set to 'represent_owner'.
        if (rentCriteria.value === CHOICES.RENT_OTHER) {
            ownershipInfo = CHOICES.REPRESENT_OWNER;
        }

        // Commercial have the commercial ownership type assigned to `ownership_type`.
        if (isCommercial()) {
            ownershipInfo = commercialOwnershipType.value;
        }
        let marketPropertyID = null;
        if (existingAddresses.value?.length > 0) {
            marketPropertyID = existingAddresses.value[0].marketPropertyID;
        }
        const created_from_prosumer_onboarding_flow =
            isProjectUnityEligible() &&
            propertyType.value === 'residential' &&
            productInterest.value.includes(CHOICES.EV_CHARGER);

        if (created_from_prosumer_onboarding_flow && (!utilityName.value || utilityName.value === '')) {
            Sentry.captureMessage('Project Unity registration is being submitted without utility name.', 'warning');
        }

        const submittedProductInterest = updateCommercialProductInterest();

        if (storageInterest.value === CHOICES.BLANK) {
            storageInterest.value = '';
        }
        // Temporary solution while user answers question on battery storage interest twice.
        const submittedStorageInterest =
            productInterest.value.includes(CHOICES.BATTERY_STORAGE) && !storageInterest.value
                ? CHOICES.INTERESTED
                : storageInterest.value;

        const data = {
            company_name: companyName.value,
            first_name: firstName.value,
            last_name: lastName.value,
            email: email.value,
            submitted_address: fullAddress.value,
            address: streetAddress.value,
            city: city.value,
            state: state.value,
            postal_code: postalCode.value,
            county: county.value,
            latitude: latitude.value,
            longitude: longitude.value,
            property_type: propertyType.value,
            owns_property: ownsProperty.value,
            storage_interest: submittedStorageInterest,
            storage_reasons: storageReason.value,
            product_interest: productInterest.value,
            roof_age: roofAge.value,
            can_remove_trees: canRemoveTrees.value,
            monthly_electricity_cost: isOwner ? monthlyElectricityBill.value : '0.01',
            ob_roof_pin_confirmed: isOwner ? roofPinConfirmed.value : true,
            // API endpoint accepts empty strings but not null/undefined for these attributes
            ownership_type: ownershipInfo,
            phone_number: phone.value ?? '',
            organization_name: organizationName.value ?? '',
            purchase_timeline: timeToPurchase.value ?? '',
            // Project Unity specific attributes
            // If no product interest is indicated, assume user is shopping for solar.
            shopping_for: submittedProductInterest.length ? submittedProductInterest : [CHOICES.SOLAR_PV],
            utility_name: utilityName.value ?? '',
            ev_make: evVersion.value?.make ?? '',
            ev_model: evVersion.value?.model ?? '',
            ev_year: evVersion.value?.year ?? '',
            created_from_prosumer_onboarding_flow,
            market_property_id: marketPropertyID,
        };
        // We check if we are in the development environment (localhost) and return prematurely
        // to avoid a call to es-site
        // TODO: IDR-475 - move this to Axios Interceptor.
        if (process.env.NODE_ENV === 'development') {
            return {};
        }
        // TODO: for this post to work, the es-site Django requires a csrftoken to be sent.
        // The current 1QPS flow relies on the csrftoken cookie being set, Django will block
        // the post unless a recent csrftoken from Django is sent back to the endpoint somehow.
        const response = await $axios.post('/market/onboarding/api/v1/consumer/register/', data, {
            headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken },
        });

        // GROWTH TEST - IDR-1038
        if (response.status === 200 && electricityBillFile.value !== null) {
            let { prop_pk } = response.data;

            if (!prop_pk) {
                // For PR testing. We expect successful responses from the registration endpoint to contain the prop_pk field.
                await propertiesTimeToQuoteStore.getCurrentProperty();
                prop_pk = propertiesTimeToQuoteStore.current?.pk;
            }

            if (prop_pk) {
                await uploadElectricityBillFile(prop_pk);
            }
        }

        return response.data;
    }

    async function fetchAndSetExistingUserData(flashUserToken: string) {
        const response = await $axios.get<ExistingProductsApiResponse>(
            `${$config.ONBOARDING_BFF_DOMAIN}/v1/existing-products`,
            {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${flashUserToken}` },
            },
        );
        calledExistingProductInterestsEndpoint.value = true;
        if (response.status !== 200) {
            // Errors mean the user does have a flash token, but for some reason the API call failed;
            return;
        }

        const existing_products_records = response.data.records || [];
        // Set existingProductInterestsForUser to the products from the first record
        existingProductInterestsForUser.value = existing_products_records[0]?.products || [];

        // Collect all addresses, ensuring that only valid Address are included
        existingAddresses.value = existing_products_records
            .map((record: ExistingProductsRecord) => record.address)
            .filter((address): address is Address => address !== undefined);

        // TODO: Should not be dependant on addresses being present
        if (existingAddresses.value.length > 0) {
            const address = existingAddresses.value[0];

            const { hasMultiPropEnabled } = address;

            if (hasMultiPropEnabled === true) {
                multiPropStatus.value = MultiPropertyStatus.ENABLED;
            } else if (hasMultiPropEnabled === false) {
                multiPropStatus.value = MultiPropertyStatus.DISABLED;
            }
        }
    }

    // This check is called from `isExternalRedirectNeeded` on wizard store, which is called on nav.
    // since existing product interest doesn't change, we store the result once we have user auth
    // to prevent making the api call more than necessary.
    async function isProductInterestAllowed() {
        // We check if we are in the development environment (localhost) and return prematurely
        // to avoid calls to es-site and shopper-service via the BFF.
        // TODO: IDR-475 - move this to Axios Interceptor.
        if (process.env.NODE_ENV === 'development') return true;
        const flashUserToken = $cookies?.get('flash.id_token');
        if (!flashUserToken) {
            // if the user's not logged in, this action is allowed
            return true;
        }
        if (existingProductInterestsForUser.value === null) {
            try {
                await fetchAndSetExistingUserData(flashUserToken);
                // If fetching data failed, return false to redirect user
                if (!existingProductInterestsForUser.value) {
                    return false;
                }
            } catch (error) {
                // If the API call failed for any reason, return false to indicate redirection
                return false;
            }
        }
        const currentlyShoppingFor = productInterest.value || [];
        if (currentlyShoppingFor.length === 0) {
            if (shoppingFor.value != null) {
                // only used for users shopping for heat pumps
                currentlyShoppingFor.push(shoppingFor.value);
            } else if (!projectUnityTestCookieExists.value) {
                currentlyShoppingFor.push(CHOICES.SOLAR_PV);
            } else {
                // If we don't know yet what they are shopping for currently, we allow them to continue.
                return true;
            }
        }

        // Update the product interest to include the user's current shopping interest.
        productInterest.value = currentlyShoppingFor;

        // Filter out CHOICES.BATTERY_STORAGE from the productInterest array.
        // We do not allow people to shop only fp battery storage but couple it with Solar PV.
        // Therefore, it is not considered a valid new product interest to keep the a repeat user from being redirected.
        const filteredProductInterest = productInterest.value.filter((product) => product !== CHOICES.BATTERY_STORAGE);

        const isNewProductInterest = filteredProductInterest.some(
            (product) => !(existingProductInterestsForUser.value || []).includes(product),
        );
        return isNewProductInterest;
    }

    async function submitEvRefinementData() {
        // We check if we are in the development environment (localhost) and return prematurely
        // to avoid a call to es-site
        // TODO: IDR-475 - move this to Axios Interceptor.
        // if (process.env.NODE_ENV === 'development') return {};
        const data = new FormData();
        if (panelImage.value != null) {
            data.append('panelImage', panelImage.value);
        }
        if (ownedAppliances.value.length > 0) {
            ownedAppliances.value.forEach((appliance: string) => {
                data.append('existingLargeAppliances', appliance);
            });
        }
        data.append('electricalPanelLocation', panelLocation.value || '');
        data.append('distanceBetweenElectricalPanelAndEvCharger', panelToChargerDistance.value || '');
        data.append('squareFootage', `${squareFootage.value || 0}`);
        const csrfToken = $cookies?.get('csrftoken');
        const siteId = route?.value?.query?.site_id?.toString();
        const response = await $axios.patch(`market/refine-site/${siteId}/`, data, {
            withCredentials: true,
            headers: { 'X-CSRFToken': csrfToken },
        });
        return response;
    }

    function addressComponents() {
        return {
            streetAddress: streetAddress.value,
            postalCode: postalCode.value,
            latitude: latitude.value,
            longitude: longitude.value,
            city: city.value,
            county: county.value,
            state: state.value,
            fullAddress: fullAddress.value,
        };
    }
    function setAddressComponents(payload: any) {
        streetAddress.value = payload.streetAddress;
        postalCode.value = payload.postalCode;
        latitude.value = payload.latitude;
        longitude.value = payload.longitude;
        city.value = payload.city;
        county.value = payload.county;
        state.value = payload.state;
        fullAddress.value = payload.fullAddress;
    }
    function setName(payload: any) {
        firstName.value = payload.firstName;
        lastName.value = payload.lastName;
    }

    async function updateExistingAddressInfo() {
        if (process.env.NODE_ENV === 'development') return;

        const flashUserToken = $cookies?.get('flash.id_token');
        if (!flashUserToken) {
            return;
        }
        if (!existingAddresses.value.length) {
            await fetchAndSetExistingUserData(flashUserToken);
        }
        if (existingAddresses.value.length > 0) {
            setAddressComponents(existingAddresses.value[0]);
        }
    }

    function populateUserInfo() {
        const flashUserToken = $cookies?.get('flash.id_token');
        if (!flashUserToken) {
            return;
        }

        try {
            const flashData = jwtDecode<{ email: string; family_name: string; given_name: string }>(flashUserToken);
            email.value = flashData?.email || '';
            firstName.value = flashData?.given_name || '';
            lastName.value = flashData?.family_name || '';

            // Disable email if it is prefilled, user can update name but not email.
            disableEmail.value = !!email.value;
        } catch (err) {
            Sentry.captureMessage(`Error decoding flash token: ${err}`, 'error');
        }
    }

    // For the MVP, if a user is logged-in and has multi-property enabled, we don’t want them to go through authenticated onboarding.
    // TODO: modify this function once we have a better solution for multi-property users.
    async function isMultiPropUser() {
        if (process.env.NODE_ENV === 'development') return false;

        const flashUserToken = $cookies?.get('flash.id_token');
        if (!flashUserToken) {
            return false;
        }

        // If we haven't fetched existing product interests yet, do so.
        if (
            multiPropStatus.value === MultiPropertyStatus.NOT_DETERMINED &&
            !calledExistingProductInterestsEndpoint.value
        ) {
            await fetchAndSetExistingUserData(flashUserToken);
        }

        return multiPropStatus.value === MultiPropertyStatus.ENABLED;
    }

    return {
        propertyType,
        ownsProperty,
        commercialOwnershipType,
        monthlyElectricityBill,
        storageInterest,
        storageReason,
        streetAddress,
        postalCode,
        latitude,
        longitude,
        city,
        county,
        state,
        fullAddress,
        ownedAppliances,
        productInterest,
        productInterestScreenChoice,
        roofPinConfirmed,
        roofAge,
        canRemoveTrees,
        companyName,
        firstName,
        lastName,
        email,
        phone,
        referralCode,
        isCsmZip,
        progressPercentage,
        accountCreated,
        solarCalculator,
        storageSavingsDifference,
        personalization,
        sustainability,
        rooftopSolarEligibilityStatus,
        rentCriteria,
        organizationName,
        timeToPurchase,
        shoppingFor,
        inEvRefinement,
        inPartnerHandoff,
        partnerCode,
        partnerHandoffJwt,
        desiredProducts,
        evCharger,
        postalCodeUnprocessed,
        postalCodeChoice,
        evVersion,
        utilityName,
        projectUnityTestCookieExists,
        rebateValues,
        availableUtilities,
        partnerHandoffData,
        existingProductInterestsForUser,
        existingAddresses,
        solarEnergyStorage,
        batteryDistance,
        solarInverterType,
        currentSolar,
        floors,
        ductwork,
        addressComponents,
        decodeHandoffJwt,
        mapPartnerHandoffDataToRegistrationFields,
        fetchAverageElectricityBillForZip,
        fetchRebateValues,
        fetchAvailableUtilities,
        fetchPostalCodeUtilities,
        fetchAndSetExistingUserData,
        getPropData,
        fetchZipHasCsm,
        inHeatPumpFlow,
        inSolarPvFlow,
        isProjectUnityEligible,
        isProductInterestAllowed,
        submitRegistration,
        submitEvRefinementData,
        setAddressComponents,
        updateExistingAddressInfo,
        setName,
        fetchRooftopEligibility,
        isCommercial,
        populateUserInfo,
        isMultiPropUser,
        panelLocation,
        squareFootage,
        panelImage,
        panelToChargerDistance,
        ceilingHeight,
        installationTimeline,
        numberOfRooms,
        homeCoverage,
        yearBuilt,

        onboardingInitialized,
        disableEmail,
        multiPropStatus,
        // IDR Test
        csmHandoffFlag,
        shoppingForFlag,
        hasPhoneNumber,
        electricityBillFile,
        skipConfirmExistingAddress,

        // Setters
        setFirstName,
        setLastName,
        setEmail,
        setPostalCode,
        setLatitude,
        setLongitude,
        setMonthlyElectricityBill,
        setPhoneNumber,
    };
});
