import { defineStore } from 'pinia';
import { Ref, ref, watch } from 'vue';
import { useContext } from '@nuxtjs/composition-api';
import { useRegistrationStore } from '@/store/registration';
import { isFeatureEnabled, track } from '@/utils/feature_flags';

import { COMPONENTS, CHOICES, SERVICE_LEVEL, LOGGED_IN_STEPS } from '@/constants';
import { PERCENT_MULTIPLIER, SKIP_STEP_IF_KNOWN, FLOWS } from '@/store/constants';
import flows from '@/flows.json';

type StepChoice = {
    next_step?: string;
    new_flow?: string;
    new_product?: string;
};

type Step = {
    choices?: { [key: string]: StepChoice };
    next_step?: string;
};

type Flow = {
    first_step: string;
    steps: { [key: string]: Step };
};

type StepState = {
    step: string;
    flow: string;
    product: string;
};

// eslint-disable-next-line import/prefer-default-export
export const useWizardStore = defineStore('wizard', () => {
    const { $cookies, $axios, $config, route } = useContext();

    const authenticatedOnboardingEnabled = isFeatureEnabled('authenticated-onboarding', 'enabled');

    // Determine flow, based on presence of growth test cookie for the 'maybe' option test.
    function getMaybeOptionCookie() {
        if ($cookies?.get('maybe_option_test')) {
            return $cookies.get('maybe_option_test');
        }
        if ($cookies?.get('_vis_opt_exp_761_combi') === 2) {
            return 'B';
        }
        return '';
    }

    const maybeOptionTest = getMaybeOptionCookie();

    const registrationStore = useRegistrationStore();
    // currentStep, currentFlow, and currentProduct are updated as the user navigates through 1QPS as needed.
    const currentStep = ref('');
    const currentFlow: Ref<keyof typeof flows> = ref('default');
    const currentProduct = ref('');
    // firstStepState saves what the first step encountered in the flow was
    const firstStepState = ref<StepState | null>(null);
    // questions we have answers for prior to onboarding through 1QPS.
    const initialAnsweredQuestions: Ref<string[]> = ref([]);
    // List of steps encountered so far, up to the latest step in browser history.
    const stepHistory: Ref<StepState[]> = ref([]);
    // The current step the user is on in 1QPS, starting at 1.
    const currentStepNumber = ref(1);
    watch(currentStep, () => track($cookies, $config, $axios, currentStep.value.toLowerCase()));

    const djangoCsrfToken = ref('');
    const initialPageCompleted = ref('');
    const progressPercentage = ref(0);
    const progressStep = ref('');
    const authenticatedFlashUser = ref(false);
    const externalRedirectPath = ref('');
    const hpInstallerFound = ref(false);
    const submissionErrorReason = ref('');
    const showLoadingSpinner = ref(false);

    /**
     * This method is called when creating the wizard store. It reads the cookies.
     * If the user is coming from the solar calculator and already has answered some
     * of the steps, then those steps are hidden. If the user is logged in, that
     * is recorded in the 'authenticatedFlashUser' state boolean.
     */
    function initializeStoreFromCookieValues() {
        const stepIsShoppingFor = route?.value?.path?.includes(COMPONENTS.SHOPPING_FOR);
        if (stepIsShoppingFor) {
            $cookies?.set('shopping-for', 'enabled');
            registrationStore.shoppingForFlag = true;
        } else if ($cookies?.get('shopping-for') === 'enabled') {
            registrationStore.shoppingForFlag = true;
        }

        // IDR Test: CSM HandOff Screen
        if ($cookies?.get('csm-handoff-screen') === 'new-screen') {
            registrationStore.csmHandoffFlag = true;
        }
        if ($cookies?.get('solar-calculator')) {
            registrationStore.solarCalculator = true;
        }
        if ($cookies?.get('flash.id_token')) {
            authenticatedFlashUser.value = true;
        }
        if ($cookies?.get('project_unity_test') === 'is_enabled') {
            registrationStore.projectUnityTestCookieExists = true;
        }
    }
    initializeStoreFromCookieValues();

    /**
     * Anything resulting in an external redirect needs to be determined all at once
     * to prevent a race condition where two different methods decide to redirect and
     * whichever one finishes first determines where the user is redirected.
     *
     * So this 'isExternalRedirectNeeded' is a central place to determine if a redirect
     * is needed. As a side effect this method sets an 'externalRedirectPath' when
     * a redirect is needed.
     *
     * @returns False if no redirect is needed, and true if a redirect is needed
     */
    async function isExternalRedirectNeeded() {
        // If the user is in the EV refinement flow, avoid redirect
        if (registrationStore.inEvRefinement) {
            return false;
        }

        // If the user selects ev charger on ShoppingFor screen or user is on EV Charger screen
        if (registrationStore.shoppingFor === CHOICES.EV_CHARGER && registrationStore.evCharger) {
            externalRedirectPath.value = $config.EV_CHARGER_DOMAIN;
            return true;
        }

        if (registrationStore.inHeatPumpFlow()) {
            externalRedirectPath.value = `${$config.HEAT_PUMPS_DOMAIN}/dashboard`;
            return true;
        }
        if (authenticatedOnboardingEnabled) {
            // Redirect multi-prop users out of es-onboarding
            if (await registrationStore.isMultiPropUser()) {
                const { ES_DOMAIN } = $config;
                externalRedirectPath.value = `${ES_DOMAIN}/market/start/`;
                return true;
            }

            // If the user is logged in and shopping for a product they already have,
            // they need to be redirected out of es-onboarding.
            if (!(await registrationStore.isProductInterestAllowed())) {
                const { MARKET_DASHBOARD_DOMAIN } = $config;
                const productInterests = registrationStore.productInterest || [];
                if (productInterests.length > 0) {
                    const productInterestsQuery = productInterests.join('_');
                    externalRedirectPath.value = `${MARKET_DASHBOARD_DOMAIN}/?productinterests=${productInterestsQuery}`;
                } else {
                    // If the user has no product interests, redirect to the dashboard without including any products in the query string.
                    externalRedirectPath.value = MARKET_DASHBOARD_DOMAIN;
                }
                return true;
            }
        } else if (
            // Otherwise, if the user is logged in, they should be redirected out
            // of es-onboarding on most steps.
            registrationStore.inSolarPvFlow() &&
            authenticatedFlashUser.value === true &&
            !(currentStep.value in LOGGED_IN_STEPS)
        ) {
            const { ES_DOMAIN } = $config;
            externalRedirectPath.value = `${ES_DOMAIN}/market/start/`;
            return true;
        }
        return false;
    }

    // Finds steps to skip based on initially available data about the user prior to starting 1QPS.
    function findInitialStepsToSkip() {
        // TODO: with the way SKIP_STEP_IF_KNOWN is set up, something more needs to be done to see if the Address page can be skipped.
        Object.entries(SKIP_STEP_IF_KNOWN).forEach(([stepName, registrationStoreFields]) => {
            let questionAnswered = true;
            // eslint-disable-next-line
            for (const field of registrationStoreFields.skipFields) {
                if (!registrationStore[field as keyof typeof registrationStore]) {
                    questionAnswered = false;
                    break;
                }
            }
            if (stepName === COMPONENTS.PROPERTY_TYPE && !registrationStore.solarCalculator) {
                return;
            }

            if (stepName === COMPONENTS.PRODUCT_INTEREST && !registrationStore.productInterest.length) {
                return;
            }

            if (stepName === COMPONENTS.ELECTRICITY_BILL && !registrationStore.solarCalculator) {
                return;
            }

            if (questionAnswered) {
                initialAnsweredQuestions.value.push(stepName);
            }
        });
        // If you are in the solar calculator, skip!
        if (registrationStore.solarCalculator) {
            initialAnsweredQuestions.value.push(COMPONENTS.SHOPPING_FOR);
        }
    }

    /**
     * Helper function returning a list of steps encountered upto a particular step.
     * @param step A step name corresponding to the steps in 1QPS.
     * @param stepList A list of step states
     * @returns A list of all steps in stepList up to and including the step name passed in via the parameter "step".
     */
    function findStepsTillStep(step: string, stepList: StepState[]) {
        const stepsEncountered = [];
        // eslint-disable-next-line
        for (const stepState of stepList) {
            stepsEncountered.push(stepState.step);
            if (stepState.step === step) {
                return stepsEncountered;
            }
        }
        return [];
    }

    /**
     * Updates the stepHistory array in the wizard store when a user navigates to a new page.
     * Ensures the array accurately reflects steps up to the latest browser history step.
     * @param stepState An object containing a 1QPS step name and the corresponding flow and product being shopped for.
     */
    function updateStepHistory(stepState: StepState) {
        // when navigating to a new step through the on-screen buttons, pop off everything ahead of the current step.
        while (stepHistory.value.length && stepHistory.value[stepHistory.value.length - 1].step !== currentStep.value)
            stepHistory.value.pop();
        // add the new step (and associated flow and product) as the next value after the current step.
        stepHistory.value.push(stepState);
    }

    /**
     *
     * @param product A product name
     * @returns The next product in the desiredProducts field in the registration store after the product passed in.
     */
    function findNextProduct(product: string) {
        const index = registrationStore.desiredProducts.findIndex((item) => item === product);
        return index !== -1 && index < registrationStore.desiredProducts.length - 1
            ? registrationStore.desiredProducts[index + 1]
            : '';
    }

    /**
     *
     * @param flow The flow associated with the step
     * @param step The step/question
     * @param product The product being shopped for at the step.
     * @param stepsToSkip Any steps to skip - typically comprises of steps answered pre-onboarding + steps answered up to the current step.
     * @param temporaryStepAnswers -- Used by getLongestRemainingPath to store answers to any paths explored.
     * @returns the next unskipped step, flow, and product.
     */
    function findNextUnskippedStep(
        flow: string,
        step: string,
        product: string,
        stepsToSkip: string[],
        temporaryStepAnswers: { [key: string]: string } = {}, // Used by getLongestRemainingPath to get recursive longest path.
    ): StepState | undefined {
        // step and flow should always be defined.
        if (!step || !flow) return undefined;

        let newFlow = flow as keyof typeof flows;
        let newStep = step;
        let newProduct = product;
        // keep checking for steps until you find a step that is not meant to be skipped..
        while (true) {
            const flowObj: Flow = flows[newFlow];

            const stepObj = flowObj.steps[newStep];
            if (stepObj.choices) {
                // Check value in temporaryStepAnswers first. If value exists, it is associated with a hypothetical path being calculated by getLongestRemainingPath.
                let stepAnswer = temporaryStepAnswers[newStep];

                // Pull value from registration store otherwise.
                if (!stepAnswer && SKIP_STEP_IF_KNOWN[newStep].choiceField) {
                    stepAnswer = registrationStore[
                        SKIP_STEP_IF_KNOWN[newStep].choiceField as keyof typeof registrationStore
                    ] as string;
                }

                const choiceResult = stepObj.choices[stepAnswer as string];
                // On the shopping for screen, your choices determine the product you are shopping for.
                if (choiceResult.new_product) {
                    newProduct = choiceResult.new_product;
                }

                // If user choice leads to new flow
                if (choiceResult.new_flow) {
                    newFlow = choiceResult.new_flow as keyof typeof flows;

                    newStep = flows[newFlow].first_step;
                } else if (choiceResult.next_step) {
                    // If user choice leads to new step in the same flow.
                    newStep = choiceResult.next_step;
                } else {
                    return { step: COMPONENTS.VERIFY_COMPLETE as string, flow: '', product: '' };
                }
            } else if (stepObj.next_step) {
                newStep = stepObj.next_step;
            } else {
                // Check to see if there is another product flow to enter.
                newProduct = findNextProduct(newProduct);
                if (newProduct) {
                    newFlow = newProduct as keyof typeof flows;
                    newStep = flows[newFlow].first_step;
                } else {
                    // Find the appropriate contact flow
                    return { step: COMPONENTS.VERIFY_COMPLETE as string, flow: '', product: '' };
                }
            }

            if (!stepsToSkip.includes(newStep)) {
                break;
            }
        }
        return { step: newStep, flow: newFlow, product: newProduct };
    }

    /**
     *
     * Recursively finds the longest path from a given step.
     *
     * @param step - The step from which you want to find the longest remaining path of steps.
     * @param flow - The flow associated with the step.
     * @param product - The product associated with the step.
     * @param stepsToSkip - A list of steps to skip which will not be a part of the returned vpath.
     * @param temporaryStepChoiceAnswers - A list of answers to steps with choices in the path currently being explored.
     * @returns An array with a list of steps representing the logest path possible from the first step this function was called with.
     */
    function getLongestRemainingPath(
        step: string,
        flow: string,
        product: string,
        stepsToSkip: string[],
        temporaryStepChoiceAnswers: { [key: string]: string } = {},
    ) {
        // You should always have a valid step or flow defined.
        if (!step || !flow) return [];

        const flowObj = flows[flow as keyof typeof flows];
        const stepObj: Step = flowObj.steps[step as keyof typeof flowObj.steps];
        let longestPath: string[] = [step];
        const updatedStepsToSkip = [...stepsToSkip, step];
        const processNextStep = (nextStepState: any, answers: { [key: string]: string }) => {
            if (nextStepState) {
                const path = getLongestRemainingPath(
                    nextStepState.step,
                    nextStepState.flow,
                    nextStepState.product,
                    updatedStepsToSkip,
                    answers,
                );
                if (path.length + 1 > longestPath.length) {
                    longestPath = [step, ...path];
                }
            }
        };
        // Explore potential paths for all choices associated with the step.
        if (stepObj.choices) {
            // eslint-disable-next-line
            for (const choiceName in stepObj.choices) {
                /* eslint-disable @typescript-eslint/indent */
                const updatedTemporaryStepAnswers = { ...temporaryStepChoiceAnswers };

                const stepAnswer = SKIP_STEP_IF_KNOWN[currentStep.value]
                    ? registrationStore[
                          SKIP_STEP_IF_KNOWN[currentStep.value].choiceField as keyof typeof registrationStore
                      ]
                    : '';

                // Making sure that the selected choice on the current page the user is on is utilized.
                if (!(step === currentStep.value && stepAnswer && stepAnswer !== choiceName) || !stepAnswer) {
                    updatedTemporaryStepAnswers[step] = choiceName;
                }

                const nextStepState = findNextUnskippedStep(
                    flow as keyof typeof flows,
                    step,
                    product,
                    stepsToSkip,
                    updatedTemporaryStepAnswers,
                );
                processNextStep(nextStepState, updatedTemporaryStepAnswers);
            }
        } else {
            // Explores path when there is not a set of choices associated with the step.
            const nextStepState = findNextUnskippedStep(flow as keyof typeof flows, step, product, stepsToSkip, {
                ...temporaryStepChoiceAnswers,
            });
            processNextStep(nextStepState, { ...temporaryStepChoiceAnswers });
        }

        return longestPath;
    }

    // Returns the first step to send the user to.
    function firstStep(): StepState {
        /* Nick Says: To access any given page, make sure it's in constants.JS under COMPONENT_NAMES, then in flows.json add it to "test_flow".

        Then input the page name as it would appear in the browser, as the query parameter `?testpage=`. For example, if you want to go straight to the ElectricityBill, go to https://localhost:8700/onboarding/start/?testpage=ElectricityBill

        Do NOT include anything else after `/start/` in the URL.

        It will NOT work again just by changing the query in the same tab. To go to a different page, paste in a clean URL to a new tab. */

        const devOrInt = $config.DEPLOY_ENV === 'dev' || $config.DEPLOY_ENV === 'int';
        if (devOrInt && route?.value?.query?.testpage !== undefined) {
            const testPage = route?.value?.query?.testpage.toString();
            const capitalizedFirstPage = testPage.charAt(0).toUpperCase() + testPage.slice(1);
            const pageExists = Object.values(COMPONENTS).includes(capitalizedFirstPage);
            if (pageExists) {
                return {
                    step: capitalizedFirstPage,
                    flow: FLOWS.TEST_FLOW,
                    product: '',
                };
            }
        }
        // Check if firstStepState is already set (i.e., not null)
        if (firstStepState.value !== null) {
            // Return the stored state if it's already set
            return firstStepState.value;
        }

        /* Continue normal routing if prod or there's no `?testpage=` query */
        if (registrationStore.inEvRefinement) {
            const state = {
                step: COMPONENTS.GREAT_CHOICE,
                flow: FLOWS.EV_REFINEMENT,
                product: CHOICES.EV_CHARGER,
            };
            firstStepState.value = state;
            return state;
        }
        if (registrationStore.shoppingForFlag) {
            const state = {
                step: COMPONENTS.SHOPPING_FOR,
                flow: FLOWS.DEFAULT,
                product: '',
            };
            firstStepState.value = state;
            return state;
        }

        // Show postal code screen if no zip is provided.
        if (!registrationStore.postalCode) {
            const state = {
                step: COMPONENTS.POSTAL_CODE,
                flow: FLOWS.DEFAULT,
                product: '',
            };
            firstStepState.value = state;
            return state;
        }

        // Show utility page for project unity eligible users.
        if (
            registrationStore.isProjectUnityEligible() &&
            (!registrationStore.shoppingFor || registrationStore.shoppingFor === CHOICES.SOLAR_PV) &&
            !registrationStore.solarCalculator
        ) {
            const state = {
                step: COMPONENTS.UTILITY_PROVIDER,
                flow: FLOWS.DEFAULT,
                product: '',
            };
            firstStepState.value = state;
            return state;
        }

        // Show Service Error screen if not eligible for solar.
        if (
            (!registrationStore.shoppingFor || registrationStore.shoppingFor === CHOICES.SOLAR_PV) &&
            registrationStore.rooftopSolarEligibilityStatus === SERVICE_LEVEL.NONE
        ) {
            const state = {
                step: COMPONENTS.SERVICE_ERROR,
                flow: FLOWS.SERVICE_ERROR,
                product: CHOICES.SOLAR_PV,
            };
            firstStepState.value = state;
            return state;
        }

        if (registrationStore.shoppingFor) {
            if (registrationStore.shoppingFor === CHOICES.SOLAR_PV) {
                if (!registrationStore.solarCalculator) {
                    const state = {
                        step: COMPONENTS.PROPERTY_TYPE,
                        flow: FLOWS.SOLAR_PV,
                        product: CHOICES.SOLAR_PV,
                    };
                    firstStepState.value = state;
                    return state;
                }
            }
        }

        // If user does not have an explicit shoppingFor value set but has been through the solar calculator, assume they are shopping for solar.
        if (registrationStore.solarCalculator) {
            if (registrationStore.propertyType === CHOICES.RESIDENTIAL) {
                const state = {
                    step: COMPONENTS.OWNS_PROPERTY,
                    flow: FLOWS.SOLAR_RESIDENTIAL,
                    product: CHOICES.SOLAR_PV,
                };
                firstStepState.value = state;
                return state;
            }
            const state = {
                step: COMPONENTS.OWNS_PROPERTY_COMMERCIAL,
                flow: FLOWS.SOLAR_COMMERCIAL,
                product: CHOICES.SOLAR_PV,
            };
            firstStepState.value = state;
            return state;
        }

        const defaultState = {
            step: COMPONENTS.PROPERTY_TYPE,
            flow: FLOWS.SOLAR_PV,
            product: CHOICES.SOLAR_PV,
        };
        firstStepState.value = defaultState;
        return defaultState;
    }

    // Updates current flow and product user is shopping for if needed.
    function updateRouteState() {
        // update current flow, step, product if needed
        // eslint-disable-next-line
        for (const [index, stepState] of stepHistory.value.entries()) {
            if (stepState.step === currentStep.value) {
                currentFlow.value = stepState.flow as keyof typeof flows;
                currentProduct.value = stepState.product;
                currentStepNumber.value = index + 1;
                break;
            }
        }
    }
    /**
     * Returns the next step to navigate to.
     * @returns the next step state (the next step, next product, and next flow) if it exists.
     */
    function nextStep() {
        const encounteredSteps = findStepsTillStep(currentStep.value, stepHistory.value);
        const stepsToSkip = [...initialAnsweredQuestions.value, ...encounteredSteps];
        return findNextUnskippedStep(currentFlow.value, currentStep.value, currentProduct.value, stepsToSkip);
    }

    function getTotalSteps(): number {
        const stepsUntilCurrentStep = findStepsTillStep(currentStep.value, stepHistory.value);
        if (!stepsUntilCurrentStep.length) return 0;

        // Ensuring currentStep, currentFlow, and currentProduct are set appropriately.
        updateRouteState();
        const stepPath = getLongestRemainingPath(currentStep.value, currentFlow.value, currentProduct.value, [
            ...initialAnsweredQuestions.value,
            ...stepsUntilCurrentStep,
        ]);
        return currentStepNumber.value + stepPath.length - 1;
    }

    function remainingStepCount() {
        return getTotalSteps() - currentStepNumber.value;
    }

    // Returns the progress percentage so far through 1QPS.
    function getProgressPercentage() {
        const totalSteps = getTotalSteps();
        if (totalSteps === 0) return 0;

        return PERCENT_MULTIPLIER * (currentStepNumber.value / totalSteps);
    }

    // Returns a string showing the steps completed against the projected total number of steps.
    function getProgressStep() {
        const totalSteps = getTotalSteps();
        if (totalSteps === 0) return '0/0';
        return `${currentStepNumber.value}/${totalSteps}`;
    }

    async function updateInfoFromPostalCode(postalCode: any) {
        if (process.env.NODE_ENV !== 'development') {
            if (postalCode && typeof postalCode === 'string') {
                await registrationStore.fetchZipHasCsm(postalCode, null);
                await registrationStore.fetchPostalCodeUtilities(postalCode);
                await registrationStore.fetchRooftopEligibility(postalCode);
                registrationStore.postalCodeUnprocessed = false;
                if (registrationStore.rooftopSolarEligibilityStatus === SERVICE_LEVEL.NONE) {
                    registrationStore.postalCodeChoice = CHOICES.SERVICE_ERROR;
                }
            }
        }
    }
    async function onboardingInitialization() {
        await registrationStore.getPropData();
        if (registrationStore.inPartnerHandoff) {
            await registrationStore.decodeHandoffJwt();
        }
        await registrationStore.updateExistingAddressInfo();
        const { postalCode } = registrationStore;
        await updateInfoFromPostalCode(postalCode);
        findInitialStepsToSkip();
        registrationStore.onboardingInitialized = true;
    }

    return {
        // state
        currentStep,
        currentFlow,
        currentProduct,
        firstStepState,
        progressPercentage,
        progressStep,
        djangoCsrfToken,
        initialPageCompleted,
        authenticatedFlashUser,
        externalRedirectPath,
        hpInstallerFound,
        maybeOptionTest,
        stepHistory,
        currentStepNumber,
        submissionErrorReason,
        showLoadingSpinner,

        // actions and getters
        isExternalRedirectNeeded,
        nextStep,
        firstStep,
        getTotalSteps,
        remainingStepCount,
        getProgressPercentage,
        getProgressStep,
        onboardingInitialization,
        findNextUnskippedStep,
        updateRouteState,
        updateStepHistory,
        getLongestRemainingPath,
        updateInfoFromPostalCode,
    };
});
